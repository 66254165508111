<template>
  <header class="mt-16 mt-md-40">
    <div class="container">
      <slot name="breadcrumb" />
      <h1 class="mb-0" data-testid="page-title">
        {{ header }}
      </h1>
    </div>
    <div v-if="subtitle" class="container subtitle text-large mt-8">
      {{ subtitle }}
    </div>
    <slot name="divider">
      <hr class="mt-32 mt-md-48 mb-40" />
    </slot>
  </header>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.subtitle {
  color: $grey-700;
}
</style>
